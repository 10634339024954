.references-bar {
  border-radius: 0.25rem;
  background-color: #fff;
  padding-top: 2rem;
  padding-bottom: 2rem;
  position: relative;
  z-index: 99;
  ul {
    margin: 0;
    padding: 0;
    width: 100%;
    li {
      margin: 0;
      display:inline-block;
      padding: 0;
      text-align: center;
      width: 20%;
      img {
        max-width: 50%
      }
    }
    li.four {
      width: 25%;
    }
  }
}
@media all and (max-width: 640px) {
  .references-bar {
    padding: 0;
    box-shadow: none !important;
    ul {
      li {
        width: 50%;
        margin: 30px 0;
        float: none;
        img {
          max-width: 80%
        }
        &.four {
          width: 50%;
        }
      }
    }
  }
}
@media all and (max-width: 640px) {
  .no-sm-shadow {
    box-shadow: none !important;
  }
  .no-sm-border {
    border: none !important;
  }
}
.browser {
  img {
    max-width: 100%;
  }
}
.u-indicator-ver-dashed {
  position: relative;
  overflow:hidden;
  &::after {
    position: absolute;
    top: 32px;
    left: 11px;
    height: 100%;
    border-left: 2px dashed #04614E;
    content: "";
  }
  &:last-child {
    &::after {
      border-left: none;
    }
  }
  .u-icon {
    position: relative;
    z-index: 1;
    line-height: 0.7;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    transition: 0.3s ease-in-out;
    color: #fff;
    background-color: #04614E;
    border-color: #04614E;
    width: 1.5rem;
    height: 1.5rem;
    font-size: 0.625rem;
    .fas {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }
}
.product-preview {
  h3.h1 {
    font-weight: 500;
    line-height: 1.5;
    color: #04614E;
    font-size: 1.5rem;
  }
}
.hero {
  height: 100vh;
  .inner {
    padding-top:70px;
  }
  .hero-img {
    position: absolute;
    top: 0;
    right: 0;
    padding-right:0;
  }
  .get-started-btn {
    margin-right: 20px;
  }
}
@media all and (max-width: 640px) {
  .hero {
    text-align: center;
    display: block !important;
    height: auto;
    h1 {
      padding-top: 30px;
    }
    .get-started-btn {
      margin-right: 0px;
      margin-bottom: 10px;
    }
  }
}
.front-page-cta {
  background: #04614E;
  input {
    line-height: 2.1em;
    height: calc(2.1em + .75rem + 2px) !important;
  }
}
.front-page-cta-curve {
  left:0;
  right: 0;
}
@media all and (max-width: 640px) {
  .front-page-cta {
    h2 {
      font-size: 1.7em;
    }
  }
}
.fill-white {
  fill: #FFF;
}
.position-absolute {
  position: absolute;
}
#post-form {
  display:none;
}
