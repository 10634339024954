.permonth {
  font-weight: 300;
  line-height: 1.5;
  color: #04614E;
  font-size: 2rem;
}
.full-width-container {
  overflow: hidden;
  .inner-container {
    position: relative;
    height:auto;
    width: 100mv;
  }
  @media all and (min-width: 640px) {
    .right-area {
      position: absolute;
      bottom: 0;
      left: 58.333333%;
      background: #04614E;
      .inner {
        position: absolute;
        top: 50%;
        -webkit-transform: translate(0, -50%);
        transform: translate(0, -50%);
        width: 40%;
        padding-left: 3rem;
        padding-right: 3rem;
        h2, p {
          color: #FFF;
        }
      }
    }
  }
}
