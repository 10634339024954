footer {
	.logo {
		max-width: 120px
	}
	h3 {
		font-weight: 600;
		margin-bottom: 0.5rem;
		line-height: 1.5;
		color: #04614E;
		font-size: 1rem;
	}
	nav ul li a {
		color: #828286;
		font-size: 0.875rem;
		padding-top: 0.375rem;
		padding-bottom: 0.375rem;
	}
  .gp {
    max-height: 75px;
  }
}
