.sign-up-container {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
}
.sign-up-form-text {
  background: url(/img/front.jpg) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 50%;
  .overlay {
    background: rgba(4, 97, 78, 0.95);
  }
  a {
    color: #fff;
    text-decoration: underline;
  }
}
.form-col {
  width: 50%;
  .form-control {
    border: 0;
    border-bottom: 1px solid rgba(4, 97, 78, 1);
    border-radius: 0;
    outline: 0;
    box-shadow: none;
    padding-left: 0px;
  }
}
@media all and (max-width: 640px) {
  .sign-up-form-text,
  .form-col {
    width: 100%;
  }
}
