/**
 * Instead of importing the whole Bootsrap,
 * let's only get the parts we need
 */
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
/*@import "../../node_modules/bootstrap/scss/images";*/
/*@import "../../node_modules/bootstrap/scss/code";*/
@import "../../node_modules/bootstrap/scss/grid";
/*@import "../../node_modules/bootstrap/scss/tables";*/
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/dropdown";
/*@import "../../node_modules/bootstrap/scss/button-group";*/
@import "../../node_modules/bootstrap/scss/input-group";
/*@import "../../node_modules/bootstrap/scss/custom-forms";*/
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/navbar";
@import "../../node_modules/bootstrap/scss/card";
/*@import "../../node_modules/bootstrap/scss/breadcrumb";*/
/*@import "../../node_modules/bootstrap/scss/pagination";*/
/*@import "../../node_modules/bootstrap/scss/badge";*/
/*@import "../../node_modules/bootstrap/scss/jumbotron";*/
/*@import "../../node_modules/bootstrap/scss/alert";*/
/*@import "../../node_modules/bootstrap/scss/progress";*/
@import "../../node_modules/bootstrap/scss/media";
/*@import "../../node_modules/bootstrap/scss/list-group";*/
/*@import "../../node_modules/bootstrap/scss/close";*/
/*@import "../../node_modules/bootstrap/scss/toasts";*/
/*@import "../../node_modules/bootstrap/scss/modal";*/
/*@import "../../node_modules/bootstrap/scss/tooltip";*/
/*@import "../../node_modules/bootstrap/scss/popover";*/
@import "../../node_modules/bootstrap/scss/carousel";
/*@import "../../node_modules/bootstrap/scss/spinners";*/
@import "../../node_modules/bootstrap/scss/utilities";
/*@import "../../node_modules/bootstrap/scss/print";*/

$fa-font-path: "/fonts";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-free/scss/solid";
/*@import "../../node_modules/@fortawesome/fontawesome-free/scss/brands";*/

@import './typography';
@import './footer';
@import './navigation';
@import './frontpage';
@import './pricing';
@import './faq';
@import './sign-up-form';

img {
  max-width: 100%;
}

.btn-success {
	background: #04614E;
}
.btn {
	font-weight: 600;
	padding: 0.75rem 1rem;
  .fas {
    margin-left: 5px;
  }
}
.btn-sm {
  font-size: 0.875rem;
}
.flex-grow {
  flex-grow: 1
}
.u-devices-v3 {
  position: absolute;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  right: 0;
  width: 40%;
  border-radius: 4.0625rem;
  box-shadow: 10px 15px 55px 15px rgba(140, 152, 164, 0.1);
}
.u-stroke-light-blue-125 {
    stroke: #e7eaf3;
}
.u-fill-gray-75 {
    fill: #f7f7f7;
}
.u-fill-white {
    fill: #fff;
}
.u-stop-color-white {
    stop-color: #fff;
}
.u-fill-none {
    fill: none;
}
.contact-form {
  box-shadow: 0 5px 15px rgba(55, 125, 255, 0.075),
              0 5px 15px rgba(119, 131, 143, 0.075);
  .card {
    background: #04614E;
    label {
      color: #FFF;
    }
    input, textarea {
      background: #04614E;
      color: #FFF;
      border: none;
      border-bottom: 1px solid #fff;
      border-radius: 0;
      padding-left: 0px;
    }
    h3 {
      color: #FFF;
      margin-bottom: 20px;
    }
    .btn {
      background: #FFF;
      color: #04614E;
      margin-bottom: 0px;
    }
  }
}
.error {
  color: #FFF;
  background: red;
  border-radius: 5px;
  padding: 3%;
  font-size: 14px;
}
.btn {
  color: #04614e;
}
.btn-success {
  color: #FFF;
}

.carousel-control-prev-icon {
  background: no-repeat 50%/100% 100%;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
  background: no-repeat 50%/100% 100%;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}
.carousel-control-next,
.carousel-control-prev {
  opacity: 1
}
.carousel-control-next {
  right: -7.5%
}
.carousel-control-prev {
  left: -7.5%
}
.carousel-control-bg {
  background: #04614e;
  border-radius: 5px;
  padding: 10px 9px;
  display: flex;
}
