.bg-success {
  background: #04614E !important;
  .card-body p,
  .card-body h3,
  .card-body i,
  .card-body a {
    color: #FFF;
  }
  .card-body h3 {
    margin-bottom: 5px;
  }
  .card-body i.header-icon {
    font-size: 1.5em;
  }
  .card-body p {
    font-size: 0.9em;
  }
}
#search-input.form-control:focus {
  outline: 0 !important;
  border: none !important;
  box-shadow: none !important;
}
#faq-accordion {
  .accordion {
    margin-bottom: 10px;
    overflow: hidden;
    .card {
      background: none;
      border: 1px solid rgba(0,0,0,.125);
      header {
        h2 {
          margin-bottom: 0px;
          .btn {
            color: #04614E;
            text-decoration: none;
            transition: all .5s;
            .fa-minus,
            .fa-plus {
              display:none;
            }
            &.collapsed {
              .fa-plus {
                display:flex;
              }
            }
            &:not(.collapsed){
              .fa-minus {
                display: flex;
              }
            }
          }
        }
      }
    }
  }
  .open {
    .card {
      header {
        background: #04614E;
        h2 {
          border-bottom: 1px solid #ccc;
        }
        h2 .btn {
          color: #FFF;
        }
      }
      .card-body {
        background: #04614E;
        color: #FFF;
        p {
          color: #FFF;
        }
      }
    }
  }
}
