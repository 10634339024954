.relative {
  position: relative;
}
.navbar-light .navbar-toggler {
  border:none;
  color: #04614E;
}
section.blurred,
footer.blurred {
  filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}
header .navbar {
	background-color: #fff;
	box-shadow: 0 1px 10px rgba(0, 97, 78, 0.1);
	padding-top: 0;
	padding-bottom: 0;

	.navbar-brand {
		img {
			max-height: 50px
		}
	}

	.navbar-nav {
		align-items: center;
		.nav-link:not(.btn) {
			padding-top: 1.5rem;
			padding-bottom: 1.5rem;
			padding-right: 0.875rem;
			padding-left: 0.875rem;
			color: #77838f;
      font-weight: 400;
      &:hover {
        color: #04614E;
      }
		}
    .nav-link.btn.btn-success {
      color: #FFF;
    }
    .dropdown-menu {
      width: 100%;
      box-shadow: 0 5px 15px rgba(55, 125, 255, 0.075), 0 5px 15px rgba(119, 131, 143, 0.075);
      padding: 60px 0;
      &.show {
        display: -ms-flexbox !important;
        display: flex !important;
      }
      li {
        display: inline-block;
        font-size: 0.875rem;
        h3 {
          font-size: 1.1rem;
          font-weight: 600;
          line-height: 1.5;
          color: #04614E;
          font-size: 1.1rem;
        }
      }
    }
    .login-btn {
      border-left: 1px solid #CCC;
    }
    @media all and (max-width: 640px) {
      .login-btn {
        border: none;
        padding: 1.5rem .875rem;
      }
      .dropdown-menu {
        box-shadow: none;
        padding: inherit;
        border: none;
        li {
          border-top: 1px solid #f1f1f1;
          text-align: center;
          a {
            padding: 1.5rem .875rem;
            color: #484f61 !important;
            color: #77838f;
            display: block;
            font-weight: 400;
          }
        }
      }
    }
	}
  @media all and (max-width: 640px) {
    #navbarSupportedContent {
      .navbar-nav {
        .nav-item {
          width: 100%;
          text-align: center;
          border-top: 1px solid #f1f1f1;
          font-weight: 400;
          .nav-link:not(.btn) {
            font-weight: 400;
            color: #484f61 !important;
          }
          &:last-child {
            padding-top: 20px;
          }
          &:first-child {
            border: none;
          }
        }
        padding-bottom: 20px;
      }
    }
  }
}
