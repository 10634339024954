@font-face {
  font-family: Poppins;
  font-display: swap;
  src: local('Poppins Thin'), url(../fonts/Poppins-Thin.otf);
  font-weight: 100;
}
@font-face {
  font-family: Poppins;
  font-display: swap;
  src: local('Poppins ExtraLight'), url(../fonts/Poppins-ExtraLight.otf);
  font-weight: 200;
}
@font-face {
  font-family: Poppins;
  font-display: swap;
  src: local('Poppins Light'), url(../fonts/Poppins-Light.otf);
  font-weight: 300;
}
@font-face {
  font-family: Poppins;
  font-display: swap;
  src: local('Poppins Regular'), url(../fonts/Poppins-Regular.otf);
  font-weight: 400;
}
@font-face {
  font-family: Poppins;
  font-display: swap;
  src: local('Poppins Medium'), url(../fonts/Poppins-Medium.otf);
  font-weight: 500;
}
@font-face {
  font-family: Poppins;
  font-display: swap;
  src: local('Poppins SemiBold'), url(../fonts/Poppins-SemiBold.otf);
  font-weight: 600;
}
@font-face {
  font-family: Poppins;
  font-display: swap;
  src: local('Poppins SemiBold'), url(../fonts/Poppins-SemiBold.otf);
  font-weight: 700;
}
@font-face {
  font-family: Poppins;
  font-display: swap;
  src: local('Poppins Bold'), url(../fonts/Poppins-Bold.otf);
  font-weight: 700;
}
@font-face {
  font-family: Poppins;
  font-display: swap;
  src: local('Poppins ExtraBold'), url(../fonts/Poppins-ExtraBold.otf);
  font-weight: 800;
}
@font-face {
  font-family: Poppins;
  font-display: swap;
  src: local('Poppins Black'), url(../fonts/Poppins-Black.otf);
  font-weight: 900;
}

body {
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
}

body, footer nav ul li {
	font-family: "Poppins", Helvetica, Arial, sans-serif;
	-moz-osx-font-smoothing: grayscale;
	-moz-font-feature-settings: "liga", "kern";
	text-rendering: optimizelegibility;
}
p {
  color: #77838f;
  line-height: 1.7;
}

a {
  color: #04614E;
}
a:hover {
  color: #024d3d;
}
.btn-success:hover {
  background: #024d3d;
}

h1, .h1 {
  font-size: 3.5rem;
  font-weight: 600;
  line-height: 1.2;
  color: #04614E;
}
h1.smaller {
  font-weight: 600;
  line-height: 1.5;
  color: #04614E;
  margin-bottom: 0.5rem;
  font-size: 2.5rem;
}
@media all and (max-width: 640px) {
  h1, .h1 {
    font-size: 2em;
  }
  h1.smaller {
    font-size: 1.5rem;
  }
}
h2 {
  margin-bottom: 0.5rem;
  font-weight: 600;
  line-height: 1.5;
  color: #04614E;
}
h3 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.5;
  color: #04614E;
  font-size: 1.25rem;
}
@media all and (max-width: 640px) {
  h2 {
    font-size: 1.1rem;
  }
  h3 {
    font-size: 1rem;
  }
}
.text-muted {
	color: #6c757d !important;
}
.people h3 {
  font-size: 1em;
}
blockquote {
  position: relative;
  padding: 25px 20px 15px 55px;
  margin: 0 0 20px;
  color: #77838f;
}
blockquote::before{
  content: "\201C";
  font-family: Georgia, serif;
  font-size: 60px;
  font-weight: bold;
  color: #04614E;
  position: absolute;
  left: 10px;
  top:5px;
}
blockquote::after{
  content: "";
}
@media all and (max-width: 640px) {
  .display-2 {
    font-size: 3.5em !important;
  }
}
strong {
  font-weight: 600;
}
ul li {
  color: #77838f;
}
.btn-success {
  border: none !important;
  border-color: transparent;
}
